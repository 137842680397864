import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import AddCircle from "@material-ui/icons/AddCircle";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Divider from "@material-ui/core/Divider";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function creartransformador() {
  const initialState = {
    numTransformador: "",
    area: "",
    zona: "",
    fromTo: "",
    capacidad: "",
    modelo: "",
    main: "",
    voltaje: "",
  };

  const [transformador, setTransformador] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialState);

  const [txtNotificacion, setTxtNotificacion] = useState(
    "Transformador almacenado correctamente"
  );
  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const verificar = () => {
    let errorMessage = {
      numTransformador: "",
      area: "",
      zona: "",
    };

    let errors = 0;
    console.log(transformador);
    if (!transformador.numTransformador) {
      errorMessage.numTransformador =
        "Debe escribir el número del transformador";
      errors = errors + 1;
    }
    if (!transformador.area) {
      errorMessage.area =
        "Debe escribir el área donde se encuentra el transformador";
      errors = errors + 1;
    }
    if (!transformador.zona) {
      errorMessage.zona =
        "Debe escribir la zona donde se encuentra el transformador";
      errors = errors + 1;
    }
    console.log(errorMessage);
    setErrors(errorMessage);
    return errors;
  };

  const handleTransformadorSubmit = async (event) => {
    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const transformadorToSave = {
        numTransformador: transformador.numTransformador,
        area: transformador.area,
        zona: transformador.zona,
        fromTo: transformador.fromTo,
        capacidad: transformador.capacidad,
        modelo: transformador.modelo,
        main: transformador.main,
        voltaje: transformador.voltaje,
      };
      await axios
        // .post(
        //   "http://localhost:5000/api/transformador/nuevotransformador",
        //   transformadorToSave
        // )
        // .post("https://dms.tools/api/transformador/nuevotransformador", transformadorToSave)
        .post("https://epstool.com/api/transformador/nuevotransformador", transformadorToSave)
        .then((res) => {
          setTransformador(initialState);
          setErrors(initialState);
          showNotification();
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear Transformador</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Número
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="numTransformador"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        numTransformador: e.target.value,
                      });
                    },
                    type: "number",
                    value: transformador.numTransformador,
                  }}
                />
                <Danger>
                  {errors.numTransformador === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.numTransformador}
                    </div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>Main</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="main"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        main: e.target.value,
                      });
                    },
                    type: "text",
                    value: transformador.main,
                  }}
                />
              </GridItem>
            </GridContainer>
          
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>Área</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="area"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        area: e.target.value,
                      });
                    },
                    type: "text",
                    value: transformador.area,
                  }}
                />
                <Danger>
                  {errors.area === undefined ? null : (
                    <div className="invalid-feedback">{errors.area}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>Zona</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="zona"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        zona: e.target.value,
                      });
                    },
                    type: "text",
                    value: transformador.zona,
                  }}
                />
                <Danger>
                  {errors.deviceDescription === undefined ? null : (
                    <div className="invalid-feedback">{errors.zona}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Proviniencia
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="fromTo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        fromTo: e.target.value,
                      });
                    },
                    type: "text",
                    value: transformador.fromTo,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Voltaje
                </FormLabel>
              </GridItem>
              <GridItem xs={12} xs={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Voltaje
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={transformador.voltaje}
                    onChange={(e) =>
                      setTransformador({
                        ...transformador,
                        voltaje: e.target.value,
                      })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Voltaje
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="120"
                    >
                      120 V
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="208"
                    >
                      208 V
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="240"
                    >
                      240
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Capacidad
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="capacidad"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        capacidad: e.target.value,
                      });
                    },
                    type: "text",
                    value: transformador.capacidad,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Modelo
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="modelo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setTransformador({
                        ...transformador,
                        modelo: e.target.value,
                      });
                    },
                    type: "text",
                    value: transformador.modelo,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button
                fullWidth
                color="success"
                onClick={handleTransformadorSubmit}
              >
                Crear Transformador
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
