import Buttons from "views/Components/Buttons.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlert.js";
import Typography from "views/Components/Typography.js";
import CrearTransformador from "views/Transformadores/creartransformador.js";
import ReadTransformador from "views/Transformadores/readtransformador.js";
import UpdateTransformador from "views/Transformadores/updatetransformador.js";
import CrearServicioSanitario from "views/ServiciosSanitarios/crearServicioSanitario";
import ReadServicio from "views/ServiciosSanitarios/readServicioSanitario";
import Turnos from "views/Dashboard/controldeaccseso/turnos";
import DashListaEmpleados from "views/Dashboard/controldeaccseso/empleados";
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FormularioEmpleados from "views/Forms/FormularioEmpleados";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Turnos,
    layout: "/admin",
  },
  {
    path: "/intoducirEmpleado",
    name: "Introducir Colaborador",
    icon: DashboardIcon,
    component: FormularioEmpleados,
    layout: "/admin",
  },
];
export default dashRoutes;
