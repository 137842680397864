import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import AddCircle from "@material-ui/icons/AddCircle";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Divider from "@material-ui/core/Divider";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function crearServicioSanitario() {
  const initialState = {
    nombre: "",
    tipo: "",
  };

  const [transformador, setTransformador] = React.useState(initialState);
  const [servicioSanitario, setServicioSanitario] = React.useState(
    initialState
  );
  const [ubicacion, setUbicacion] = useState({
    edificio: "",
    nivel: "",
    detalle: "",
  });
  const [facilidades, setFacilidades] = useState([
    { nombre: "Orinales", cantidad: 0 },
    { nombre: "Inodoros", cantidad: 0 },
    { nombre: "Lavamanos", cantidad: 0 },
    { nombre: "Secamanos", cantidad: 0 },
    { nombre: "Puertas", cantidad: 0 },
  ]);
  const [errors, setErrors] = React.useState({
    nombre: "",
    tipo: "",
    edificio: "",
    nivel: "",
  });

  const [txtNotificacion, setTxtNotificacion] = useState(
    "Servicio sanitarios almacenado correctamente"
  );
  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleSimple = (event, id) => {
    event.preventDefault();
    let txt = event.target.value;
    facilidades[id].cantidad = txt;
    setFacilidades([...facilidades]);

    // arrTipo[id] = event.target.value;
    // setArrTipo([...arrTipo]);
  };

  const verificar = () => {
    let errorMessage = {
      nombre: "",
      tipo: "",
      edificio: "",
      nivel: "",
    };

    let errors = 0;
    if (!servicioSanitario.nombre) {
      errorMessage.nombre = "Debe escribir el nombre del servicio sanitario";
      errors = errors + 1;
    }
    if (!servicioSanitario.tipo) {
      errorMessage.tipo = "Debe seleccionar el tipo del servicio sanitario";
      errors = errors + 1;
    }
    if (!ubicacion.edificio) {
      errorMessage.edificio =
        "Debe seleccionar el edificio donde se encuentra el servicio sanitario";
      errors = errors + 1;
    }
    if (!ubicacion.nivel) {
      errorMessage.nivel =
        "Debe seleccionar el nivel donde se encuentra el servicio sanitario";
      errors = errors + 1;
    }
    setErrors(errorMessage);
    return errors;
  };

  const handleServicioSanitarioSubmit = async (event) => {
    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const servicioSanitarioToSave = {
        nombre: servicioSanitario.nombre,
        ubicacion: ubicacion,
        tipo: servicioSanitario.tipo,
        facilidades: facilidades,
      };
      await axios
        // .post(
        //   "http://localhost:5005/api/limpieza/createserviciosanitario",
        //   servicioSanitarioToSave
        // )
        .post(
          "https://epstool.com/api/limpieza/createserviciosanitario",
          servicioSanitarioToSave
        )
        // .post("https://dms.tools/api/transformador/nuevotransformador", transformadorToSave)
        // .post(
        //   "https://epstool.com/api/transformador/nuevotransformador",
        //   transformadorToSave
        // )
        .then((res) => {
          setServicioSanitario(initialState);
          setErrors({ nombre: "", tipo: "", edificio: "", nivel: "" });
          setFacilidades([
            { nombre: "Orinales", cantidad: 0 },
            { nombre: "Inodoros", cantidad: 0 },
            { nombre: "Lavamanos", cantidad: 0 },
            { nombre: "Secamanos", cantidad: 0 },
            { nombre: "Puertas", cantidad: 0 },
          ]);
          setUbicacion({
            edificio: "",
            nivel: "",
            detalle: "",
          });
          showNotification();
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear servicio sanitario</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridItem xs={12} sm={12}>
              <div className={classes.typo}>
                <h4>Detalles</h4>
              </div>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Nombre
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="nombreSS"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setServicioSanitario({
                        ...servicioSanitario,
                        nombre: e.target.value,
                      });
                    },
                    type: "text",
                    value: servicioSanitario.nombre,
                  }}
                />
                <Danger>
                  {errors.nombre === undefined ? null : (
                    <div className="invalid-feedback">{errors.nombre}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>Tipo</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Tipo
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={servicioSanitario.tipo}
                    onChange={(e) =>
                      setServicioSanitario({
                        ...servicioSanitario,
                        tipo: e.target.value,
                      })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Tipo
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Hombres"
                    >
                      Hombres
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Mujer"
                    >
                      Mujeres
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Discapacitados"
                    >
                      Discapacitados
                    </MenuItem>
                  </Select>
                </FormControl>
                <Danger>
                  {errors.tipo === undefined ? null : (
                    <div className="invalid-feedback">{errors.tipo}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <div className={classes.typo}>
                  <h4>Ubicación</h4>
                </div>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Edificio
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Edificio
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={ubicacion.edificio}
                    onChange={(e) =>
                      setUbicacion({
                        ...ubicacion,
                        edificio: e.target.value,
                      })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Edificio
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Legacy"
                    >
                      Legacy
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Bex"
                    >
                      Bex
                    </MenuItem>
                  </Select>
                </FormControl>
                <Danger>
                  {errors.edificio === undefined ? null : (
                    <div className="invalid-feedback">{errors.edificio}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>Nivel</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Nivel
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={ubicacion.nivel}
                    onChange={(e) =>
                      setUbicacion({
                        ...ubicacion,
                        nivel: e.target.value,
                      })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Nivel
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Primero"
                    >
                      Primero
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Segundo"
                    >
                      Segundo
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Tercero"
                    >
                      Tercero
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Cuarto"
                    >
                      Cuarto
                    </MenuItem>
                  </Select>
                </FormControl>
                <Danger>
                  {errors.nivel === undefined ? null : (
                    <div className="invalid-feedback">{errors.nivel}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Detalles
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  id="detalles"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setUbicacion({
                        ...ubicacion,
                        detalle: e.target.value,
                      });
                    },
                    type: "text",
                    value: ubicacion.detalle,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <div className={classes.typo}>
                  <h4>Facilidades</h4>
                </div>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Orinales
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="orinales"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleSimple(e, 0),
                    type: "Number",
                    value: facilidades[0].cantidad,
                  }}
                />
                <Danger>
                  {errors.area === undefined ? null : (
                    <div className="invalid-feedback">{errors.area}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Inodoros
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="Inodoros"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleSimple(e, 1),
                    type: "Number",
                    value: facilidades[1].cantidad,
                  }}
                />
                <Danger>
                  {errors.area === undefined ? null : (
                    <div className="invalid-feedback">{errors.area}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Lavamanos
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="Lavamanos"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleSimple(e, 2),
                    type: "Number",
                    value: facilidades[2].cantidad,
                  }}
                />
                <Danger>
                  {errors.area === undefined ? null : (
                    <div className="invalid-feedback">{errors.area}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Secamanos
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="Secamanos"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleSimple(e, 3),
                    type: "Number",
                    value: facilidades[3].cantidad,
                  }}
                />
                <Danger>
                  {errors.area === undefined ? null : (
                    <div className="invalid-feedback">{errors.area}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Puertas de acceso
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="puertas"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleSimple(e, 4),
                    type: "Number",
                    value: facilidades[4].cantidad,
                  }}
                />
                <Danger>
                  {errors.area === undefined ? null : (
                    <div className="invalid-feedback">{errors.area}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          </form>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button
                fullWidth
                color="success"
                onClick={handleServicioSanitarioSubmit}
              >
                Crear servicio sanitario
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
