import React, { useEffect, useState } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Forward from "@material-ui/icons/Forward";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { blue, red, yellow } from "@material-ui/core/colors";
import { green } from "@material-ui/core/colors";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import { Table, TableContainer } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const [serviciosSanitarios, setServiciosSanitarios] = useState([]);
  const hoy = Math.floor(new Date().getTime() / 1000.0);

  // CARGA DE LA RONDA
  const fetchDataTransformadores = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // "http://localhost:5005/api/limpieza/readallserviciossanitarios"
      "https://epstool.com/api/limpieza/readallserviciossanitarios"
    );
    // console.log(result.data);
    setServiciosSanitarios(result.data);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchDataTransformadores();
  }, []);
  let tbServiciosSanitarios;
  Object.keys(serviciosSanitarios).length >= 1
    ? (tbServiciosSanitarios = serviciosSanitarios.map((servicio) => {
      return {
        nombre: servicio.nombre,
        tipo: servicio.tipo,
        edificio: servicio.ubicacion.edificio,
        estado:
          servicio.estado === 1
            ? "Habilitado"
            : servicio.estado === 2
              ? "Fuera de servicio"
              : servicio.estado === 3
                ? "En limpieza"
                : "Sin estado",
        ultimaLimpieza: servicio.ultimaLimpieza ? new Date(servicio.ultimaLimpieza * 1000).toLocaleString() : "",
        atencion:
          servicio.estado === 3 ? (
            <div>
              <FiberManualRecordIcon style={{ color: blue[500] }} />
            </div>
          ) : hoy - servicio.ultimaLimpieza < 7200 ? (
            <div>
              <FiberManualRecordIcon style={{ color: green[500] }} />
            </div>
          ) : hoy - servicio.ultimaLimpieza > 7200 ? (
            <div>
              <FiberManualRecordIcon style={{ color: red[500] }} />
            </div>
          ) : (
            <div>
              <FiberManualRecordIcon style={{ color: grayColor[1000] }} />
            </div>
          ),
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => {
                props.history.push(`serviciosanitario/${servicio._id}`);
                // alert("Deshabilitado");
              }}
              color="success"
              className="edit"
            >
              <Forward />
            </Button>{" "}
          </div>
        ),
      };
    }))
    : (tbServiciosSanitarios = []);

  return (
    <GridContainer>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: "Ubicación",
                    accessor: "nombre",
                  },
                  {
                    Header: "Tipo",
                    accessor: "tipo",
                  },
                  {
                    Header: "Edificio",
                    accessor: "edificio",
                  },
                  {
                    Header: "Ult. Limp",
                    accessor: "ultimaLimpieza",
                  },
                  {
                    Header: "Estado",
                    accessor: "estado",
                  },
                  {
                    Header: "Atención",
                    accessor: "atencion",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={tbServiciosSanitarios}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}




