import { CardMedia } from "@material-ui/core";
import { Label } from "@mui/icons-material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import classNames from "classnames";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import Typography from "views/Components/Typography";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";

const url = "https:44.226.167.146/artemis/api/resource/v1/person/single/add";
const signatureAgregar = "hf4MToVjamo8/i48pn97pt5D9FkMS/joGowmupnuhDM=";
const key = "23029107";
const signatureHeaders = "x-ca-key";

const headers = {
  "x-ca-key": key,
  "x-ca-signature": signatureAgregar,
  "x-ca-signature-headers": signatureHeaders,
  "Content-Type": "application/json",
  Accept: "*/*",
};

// user the form data and send it to the server

const FormularioEmpleados = () => {
  const [image, serImage] = useState("");
  const [base64, setBase64] = useState("");
  const convertBase64 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = reader.result
        .replace(/^data:image\/[a-z]+;base64,/, "")
        .toString();
      setBase64(image.toString());
      serImage(reader.result.toString());
    };

    reader.readAsDataURL(file);
  };
  const enviarDatos = async (values) => {
    const data = {
      personCode: `${values.personCode}`,
      personFamilyName: `${values.personFamilyName}`,
      personGivenName: `${values.personGivenName}`,
      gender: 1,
      orgIndexCode: "1",
      remark: "description",
      phoneNo: "60671637",
      email: "",
      faces: [
        {
          faceData: `${base64}`,
        },
      ],
      beginTime: "2020-05-26T15:00:00+08:00",
      endTime: "2030-05-26T15:00:00+08:00",
    };
    const axiosData = await axios
      .post(url, data, { headers: headers })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(axiosData);
  };

  return (
    <GridContainer>
      <Card>
        <Formik
          initialValues={{
            personCode: "",
            personFamilyName: "",
            personGivenName: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.personCode) {
              errors.personCode = "Required";
            }
            if (!values.personFamilyName) {
              errors.personFamilyName = "Required";
            }

            if (!values.personGivenName) {
              errors.personGivenName = "Required";
            }
            return errors;
          }}
          onSubmit={enviarDatos}
        >
          <Form style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <label
                style={{ color: "blue", marginBottom: "2%", fontSize: "20px" }}
                htmlFor="file"
              >
                Detalle de colaborador
              </label>
              <Field
                style={{ width: "50%", marginBottom: "2%" }}
                as={TextField}
                autoComplete="personCode"
                type="number"
                name="personCode"
                variant="outlined"
                required
                fullWidth
                id="personCode"
                label="Codigo de empleado"
                autoFocus
              />
              <Field
                style={{ width: "50%", marginBottom: "2%" }}
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="personFamilyName"
                label="Apellido"
                name="personFamilyName"
                autoComplete="personFamilyName"
              />
              <Field
                style={{ width: "50%", marginBottom: "2%" }}
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="personGivenName"
                label="Nombre"
                name="personGivenName"
                autoComplete="personGivenName"
              />
              <label
                style={{ color: "blue", marginBottom: "2%", fontSize: "20px" }}
                htmlFor="file"
              >
                Insertar imagen de colaborador
              </label>
              <Field
                style={{ width: "50%", marginBottom: "2%" }}
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="image"
                name="image"
                type="file"
                onChange={convertBase64}
              />
              <ErrorMessage name="image" component="div" />
              <Button
                style={{ width: "50%", marginBottom: "2%" }}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Agregar
              </Button>
            </Box>
          </Form>
        </Formik>
      </Card>
    </GridContainer>
  );
};
export default FormularioEmpleados;
