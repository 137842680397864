import React, { useEffect, useState } from "react";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import { Button, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import ReactTable from "components/ReactTable/ReactTable.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";

const signatureObtener = "2tnF7ukdyiOgEAWw6sXwg2Vq8dEhJINj7w3c5CUhES8=";
const key = "23029107";
const signatureHeaders = "x-ca-key";
const url3 = "https://44.226.167.146/artemis/api/acs/v1/door/events";

const headers = {
  "x-ca-key": key,
  "x-ca-signature": signatureObtener,
  "x-ca-signature-headers": signatureHeaders,
  "Content-Type": "application/json",
  Accept: "*/*",
};

const Turnos = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nuevaFecha, setNuevaFecha] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const today = Date.now();
  const todayFormat = new Date(today);
  const [turnoA, setTurnoA] = useState({total:[], regular: [], extra:[]});
  const [turnoB, setTurnoB] = useState({total:[], regular: [], extra:[]});
  const [turnoC, setTurnoC] = useState({total:[], regular: [], extra:[]});
  // let turnoA = 0;
  // let turnoB = 0;
  // let turnoC = 0;

  let hash = {};
  const filter1 = filteredData.filter((o) =>
    hash[o.personId] ? false : (hash[o.personId] = true)
  );

  const filters = async (dataTotal) => {
    let turnoATotal = [];
    let turnoARegular = [];
    let turnoAExtra = [];
    let turnoBTotal = [];
    let turnoBRegular = [];
    let turnoBExtra = [];
    let turnoCTotal = [];
    let turnoCRegular = [];
    let turnoCExtra = [];

    dataTotal.forEach((item) => {
      item.dia = item.eventTime.split("T")[0];
      item.hora =
        item.eventTime.split("T")[1].split(".")[0].split(":")[0] +
        ":" +
        item.eventTime.split("T")[1].split(".")[0].split(":")[1];
      let itemHour = item.eventTime.split("T")[1].split(":")[0];
     
      if (itemHour >= 4 && itemHour <= 11) {
        turnoATotal.push(item);
      } else if (itemHour >= 12 && itemHour <= 17) {
        turnoBTotal.push(item);
      } else if (itemHour >= 18 && itemHour <= 23) {
        turnoCTotal.push(item);
      }
    });
    let hash1 = {};
    let hash2 = {};
    let hash3 = {};
    const filterA = turnoATotal.filter((o) =>
      hash1[o.personId] ? false : (hash1[o.personId] = true)
    );
    const filterB = turnoBTotal.filter((o) =>
      hash2[o.personId] ? false : (hash2[o.personId] = true)
    );
    const filterC = turnoCTotal.filter((o) =>
      hash3[o.personId] ? false : (hash3[o.personId] = true)
    );
    filterA.forEach(item => {
      if (item.checkInAndOutType === 1) {
        item.tipo = 'Entrada'
        turnoARegular.push(item);
      } else {
        item.tipo = 'Extra'
        turnoAExtra.push(item);
      }
    });
    filterB.forEach(item => {
      if (item.checkInAndOutType === 1) {
        item.tipo = 'Entrada'
        turnoBRegular.push(item);
      } else {
        item.tipo = 'Extra'
        turnoBExtra.push(item);
      }
    });
    filterC.forEach(item => {
      if (item.checkInAndOutType === 1) {
        item.tipo = 'Entrada'
        turnoCRegular.push(item);
      } else {
        item.tipo = 'Extra'
        turnoCExtra.push(item);
      }
    });
    setTurnoA({total: filterA, regular: turnoARegular, extra: turnoAExtra});
    setTurnoB({total: filterB, regular: turnoBRegular, extra: turnoBExtra});
    setTurnoC({total: filterC, regular: turnoCRegular, extra: turnoCExtra});
    console.log(turnoB);
  };

  const updateData = async (startDate, endDate) => {
    try {
      let dates = {startDate: startDate, endDate: endDate}
       await axios
        .get('https://epstool.com/api/limpieza/accesoDispositivo', {params: dates})
        // .get('http://localhost:5050/api/limpieza/accesoDispositivo', {params: dates})
        .then((response) => {
          setData(response.data.data.list);
          setFilteredData(response.data.data.list);
          filters(response.data.data.list);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  function toISOStringLocal(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    if (d.getHours() <= 5) {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate() - 1) +
        "T" +
        "10" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    } else {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        "10" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    }
  }

  function toISOStringLocalEnd(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    if (d.getHours() <= 5) {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        "10" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    } else {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate() + 1) +
        "T" +
        "10" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    }
  }

  function toISOStringLocalMoment(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    if (d.getHours() <= 6) {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        "06" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    } else {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate() + 1) +
        "T" +
        "06" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    }
  }

  function toISOStringLocalEndMoment(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    if (d.getHours() <= 6) {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate() + 1) +
        "T" +
        "10" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    } else {
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        "10" +
        ":" +
        "00" +
        ":" +
        "00"
      );
    }
  }

  function toReadStringLocal(d) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    return z(d.getDate()) + "-" + z(d.getMonth() + 1) + "-" + d.getFullYear();
  }

  const actualizarFecha = async () => {
    let inicioDate;
    let endDate;
    nuevaFecha
      ? (inicioDate = toISOStringLocalMoment(nuevaFecha))
      : (nuevaFecha = 0);
    nuevaFecha
      ? (endDate = toISOStringLocalEndMoment(nuevaFecha))
      : (nuevaFecha = 0);
    // console.log(todayFormat.getHours());
    if (inicioDate) {
      updateData(inicioDate, endDate);
    }
  };

  useEffect(() => {
    let inicioDate;
    let endDate;

    todayFormat ? (inicioDate = toISOStringLocal(todayFormat)) : null;
    todayFormat ? (endDate = toISOStringLocalEnd(todayFormat)) : null;

    if (inicioDate) {
      updateData(inicioDate, endDate);
    }
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12}>
                <h4>Reporte de acceso {toReadStringLocal(todayFormat)}</h4>
              </GridItem>
              <GridItem xs={12} md={4}>
                <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "Escoger fecha" }}
                  onChange={(value) => setNuevaFecha(value._d)}
                />
              </GridItem>
              <GridItem>
                <Button
                  size="sm"
                  onClick={() => {
                    actualizarFecha();
                  }}
                >
                  Cambiar Fecha
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <TableContainer style={{ marginBottom: "20px" }} component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                    <StyledTableCell align="center">Turno A</StyledTableCell>
                    <StyledTableCell align="center">Turno B</StyledTableCell>
                    <StyledTableCell align="center">Turno C</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell align="center">
                      Regular
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoA.regular.length ? turnoA.regular.length : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoB.regular.length ? turnoB.regular.length : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoC.regular.length ? turnoC.regular.length : 0}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                     <StyledTableCell align="center">
                      Extras
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoA.extra.length ? turnoA.extra.length : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoB.extra.length ? turnoB.extra.length : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoC.extra.length ? turnoC.extra.length : 0}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                     <StyledTableCell align="center">
                      Total
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoA.total.length ? turnoA.total.length : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoB.total.length ? turnoB.total.length : 0}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {turnoC.total.length ? turnoC.total.length : 0}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <h3>Turno A</h3>
                <ReactTable
                  columns={[
                    {
                      Header: "Nombre",
                      accessor: "personName",
                    },
                    {
                      Header: "Dia",
                      accessor: "dia",
                    },
                    {
                      Header: "Hora",
                      accessor: "hora",
                    },
                    {
                      Header: "Tipo de Marca",
                      accessor: "tipo",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                    },
                  ]}
                  data={turnoA.total}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
              <h3>Turno B</h3>
                <ReactTable
                  columns={[
                    {
                      Header: "Nombre",
                      accessor: "personName",
                    },
                    {
                      Header: "Dia",
                      accessor: "dia",
                    },
                    {
                      Header: "Hora",
                      accessor: "hora",
                    },
                    {
                      Header: "Tipo de Marca",
                      accessor: "tipo",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                    },
                  ]}
                  data={turnoB.total}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
              <h3>Turno C</h3>
                <ReactTable
                  columns={[
                    {
                      Header: "Nombre",
                      accessor: "personName",
                    },
                    {
                      Header: "Dia",
                      accessor: "dia",
                    },
                    {
                      Header: "Hora",
                      accessor: "hora",
                    },
                    {
                      Header: "Tipo de Marca",
                      accessor: "tipo",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                    },
                  ]}
                  data={turnoC.total}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export default Turnos;
