import React, { useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import ReactFilestack from "filestack-react";
import FormLabel from "@material-ui/core/FormLabel";
import jwt_decode from "jwt-decode";

// import jwt_decode from "jwt-decode";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import CustomInput from "components/CustomInput/CustomInput.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Forward from "@material-ui/icons/Forward";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success.js";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardFooter from "components/Card/CardFooter";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReadTransformador(props) {
  const classes = useStyles();
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const options = {
    maxFiles: 5,
    fromSources: ["local_file_system", "audio"],
  };

  const [trabajo, setTrabajo] = React.useState({
    comentario: "",
    fechaCambio: "",
    contratistaNombre: "",
    contratistaEmpresa: "",
  });
  const [nameDescription, setNameDescription] = React.useState("");
  const [preDeleteId, setPreDeleteId] = React.useState();
  const [eliminarNotaModal, setEliminarNotaModal] = React.useState(false);
  const [transformador, setTransformador] = React.useState();
  const [alertModal, setAlertModal] = React.useState(false);
  const [trabajosModal, setTrabajosModal] = React.useState(false);
  const [panelModal, setPanelModal] = React.useState(false);
  const [urlFile, setUrlFile] = React.useState([]);
  const [urlFileError, setUrlFileError] = React.useState();
  const [urlArchivo, setUrlArchivo] = React.useState();
  const [urlArchivoError, setUrlArchivoError] = React.useState();
  const [txtNotificacion, setTxtNotificacion] = React.useState("");
  const [tc, setTC] = React.useState(false);
  const [controlCambios, setControlCambios] = React.useState([]);
  const [codigoPanel, setCodigoPanel] = React.useState("");
  const [notaModal, setNotaModal] = React.useState(false);
  const [notas, setNotas] = React.useState([]);
  const [textNote, setTextNote] = React.useState("");
  const [filesModal, setFilesModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [errorDescription, setErrorDescription] = React.useState("");
  const [eliminarFileModal, setEliminarFileModal] = React.useState(false);

  const fetchData = async () => {
    const result = await axios.get(
      //   `https://dms.rocks/api/vaisala/location/${props.match.params.codigo}`
      // `/api/vaisala/location/${props.match.params.codigo}`
      `https://epstool.com/api/transformador/read/${props.match.params.numerotransformador}`
      // `http://localhost:5000/api/transformador/read/${props.match.params.numerotransformador}`
      // `http://dms.tools/api/transformador/${props.match.params.numerotransformador}`
    );
    setTransformador(result.data);
    setControlCambios(result.data.controlCambios);
    setNotas(result.data.notas);
    setFiles(result.data.files);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const notaToSave = {
      nota: textNote,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(
        `https://epstool.com/api/transformador/nota/${transformador._id}`,
        // `http://localhost:5000/api/transformador/nota/${transformador._id}`,
        notaToSave
      )
      .then((res) => {
        setNotas(res.data.transformador.notas);
        setTxtNotificacion("Nota guardada con éxito");
        setTextNote("");
        setNotaModal(false);
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // Eliminar Nota
  const deleteNota = async (idNota, idTrafo) => {
    await axios
      .delete(
        // `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/transformador/nota/${idTrafo}/${idNota}`
        `https://epstool.com/api/transformador/nota/${idTrafo}/${idNota}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        setNotas(res.data.notas);
        setEliminarNotaModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Nota eliminada con éxito");
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // GUARDAR Trabajo
  const handleTrabajoSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    let filesToSave = [];
    urlFile.forEach((archivo) => {
      let archivoToSave = {
        url: archivo.url,
        fileName: archivo.filename,
        type: archivo.mimetype,
      };
      filesToSave.push(archivoToSave);
    });
    const trabajoToSave = {
      comentario: trabajo.comentario,
      fechaCambio: trabajo.fechaCambio,
      contratistaNombre: trabajo.contratistaNombre,
      contratistaEmpresa: trabajo.contratistaEmpresa,
      filesCambios: filesToSave,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(
        `https://epstool.com/api/transformador/trabajo/${transformador._id}`,
        // `http://localhost:5000/api/transformador/trabajo/${transformador._id}`,
        trabajoToSave
      )
      .then((res) => {
        // setNotas(res.data.polo.notas);
        setControlCambios(res.data.transformador.controlCambios);
        setTxtNotificacion("Trabajo guardado con éxito");
        setTrabajosModal(false);
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  //Manejo de archivos
  const handleFileSubmit = async (event) => {
    event.preventDefault();
    setUrlArchivoError();
    setErrorDescription();
    let flag = 0;
    !nameDescription
      ? setErrorDescription("Debe agregar un nombre al archivo")
      : (flag = flag + 1);
    !urlArchivo
      ? setUrlArchivoError("Debe adjuntar un documento o imagen")
      : (flag = flag + 1);

    if (flag === 2) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const fileToSave = {
        url: urlArchivo.url,
        fileName: urlArchivo.filename,
        fileDescription: nameDescription,
        type: urlArchivo.mimetype,
        userName: decoded.name,
        userCompany: decoded.company,
      };
      await axios
        .post(`https://epstool.com/api/transformador/file/${transformador._id}`, fileToSave)
        // .post(
        //   `http://localhost:5000/api/transformador/file/${transformador._id}`,
        //   fileToSave
        // )
        // .post(`/api/maquinas/file/${equipo._id}`, fileToSave)
        .then((res) => {
          setFiles(res.data.transformador.files);
          setTxtNotificacion("Archivo guardado con éxito");
          setNameDescription("");
          setUrlArchivo("");
          setFilesModal(false);
          showNotification();
        })
        .catch((error) => {
          setTxtNotificacion("Se ha producido un error");
          showNotification();
        });
    }
  };

  // Eliminar Archivo
  const deleteFile = async (idArchivo, idTrafo) => {
    await axios
      .delete(
        // `https://epstool.com/api/polos/heredia/${codigoPolo}/${zona}/${id}/${tipo}`
        // `http://localhost:5000/api/transformador/file/${idTrafo}/${idArchivo}`
        `https://epstool.com/api/transformador/file/${idTrafo}/${idArchivo}`
      )
      // .delete(`/api/polos/${codigoPolo}/${zona}/${id}/${tipo}`)
      .then((res) => {
        setFiles(res.data.files);
        setEliminarFileModal(false);
        setPreDeleteId("");
        setTxtNotificacion("Archivo eliminado con éxito");
        showNotification();
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {transformador ? `Transformador ${transformador.etiqueta}` : ""}
              </h4>
            </CardHeader>
            <CardBody>
              <br></br>
              <GridContainer>
                <GridItem xs={12}>
                  <strong>Información detallada:</strong>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>
                    {transformador
                      ? transformador.area
                        ? `Area: ${transformador.area}`
                        : "Area: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {transformador
                      ? transformador.zona
                        ? `Zona: ${transformador.zona}`
                        : "Zona: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {transformador
                      ? transformador.fromTo
                        ? `From To: ${transformador.fromTo}`
                        : "From To: No hay registro"
                      : null}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>
                    {transformador
                      ? transformador.capacidad
                        ? `Capacidad: ${transformador.capacidad}`
                        : "Capacidad: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {transformador
                      ? transformador.main
                        ? `Main: ${transformador.main}`
                        : "Main: No hay registro"
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {transformador
                      ? transformador.voltaje
                        ? `Voltaje: ${transformador.voltaje}`
                        : "Voltaje: No hay registro"
                      : null}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p>
                    {transformador
                      ? transformador.modelo
                        ? `Modelo: ${transformador.modelo}`
                        : "Modelo: No hay registro"
                      : null}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="success"
                        // onClick={() => {
                        //   setCambiarModal(true);
                        // }}
                        onClick={() => setTrabajosModal(true)}
                      >
                        Añadir trabajo
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          props.history.push(
                            `../editar/${transformador.numTransformador}`
                          );
                        }}
                      >
                        Editar
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="danger"
                        // onClick={() => {
                        //   setEliminarModal(true);
                        // }}
                      >
                        Eliminar
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {/* 
                    <GridItem xs={12} sm={4}>
                      <Button
                        size="sm"
                        fullWidth
                        className={classes.cardButtonTitle}
                        color="success"
                        onClick={() => {
                          setPanelModal(true);
                        }}
                      >
                        Indexar Tablero
                      </Button>
                    </GridItem>
                     */}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Espacio de control de cambios */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridItem xs={12} sm={12} md={9}>
                <h4 className={classes.cardTitle}>Control de cambios</h4>
              </GridItem>
            </CardHeader>
            <CardBody>
              {controlCambios.length === 0
                ? "No hay cambios registrados"
                : controlCambios.map((cambio) => (
                    <GridContainer key={cambio._id}>
                      <GridItem xs={9}>
                        <strong>
                          Cambio realizado el dia{" "}
                          {new Date(cambio.fechaCambio * 1000).toLocaleString()}
                          :
                        </strong>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>{cambio.comentario}.</p>
                        {cambio.filesCambios[0] ? (
                          <ul>
                            {cambio.filesCambios.map((archivo) => (
                              <li key={archivo.url}>
                                <a href={archivo.url}>{archivo.fileName}</a>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          " "
                        )}
                      </GridItem>
                      <GridItem xs={12}>
                        <p>
                          Realizado por {cambio.contratistaNombre} -{" "}
                          {cambio.contratistaEmpresa}
                        </p>
                      </GridItem>
                    </GridContainer>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Espacio de control de Notas */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitle}>Notas</h4>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setNotaModal(true);
                    }}
                  >
                    Añadir nota
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {notas.length === 0
                ? "No hay notas registradas"
                : notas.map((nota) => (
                    <GridContainer key={nota._id}>
                      <GridItem xs={10}>
                        <strong>
                          Nota del dia{" "}
                          {new Date(nota.fecha * 1000).toLocaleString()}:
                        </strong>
                      </GridItem>
                      <GridItem xs={2}>
                        <Button
                          justIcon
                          color="danger"
                          size="sm"
                          onClick={() => {
                            setPreDeleteId(nota._id);
                            setEliminarNotaModal(true);
                          }}
                        >
                          <Delete />
                        </Button>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>{nota.comentario}.</p>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>
                          Realizado por {nota.userName} - {nota.userCompany}
                        </p>
                      </GridItem>
                    </GridContainer>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Espacio de control de archivos */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} md={9}>
                  <h4 className={classes.cardTitle}>Archivos</h4>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setFilesModal(true);
                    }}
                  >
                    Añadir archivo
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {files.length === 0
                ? "No se registran archivos"
                : files.map((file) => (
                    <GridContainer key={file._id}>
                      <GridItem xs={10}>
                        <strong>
                          Archivo almacendo el dia{" "}
                          {new Date(file.fecha * 1000).toLocaleString()}:
                        </strong>
                      </GridItem>
                      <GridItem xs={2}>
                        <Button
                          justIcon
                          color="danger"
                          size="sm"
                          onClick={() => {
                            setPreDeleteId(file._id);
                            setEliminarFileModal(true);
                          }}
                        >
                          <Delete />
                        </Button>
                      </GridItem>
                      <GridItem xs={12}>
                        <strong>
                          <a href={file.url} target="_blank">
                            Ver archivo {file.fileDescripcion}
                          </a>
                        </strong>
                      </GridItem>
                      <GridItem xs={12}>
                        <p>
                          Realizado por {file.userName} - {file.userCompany}
                        </p>
                      </GridItem>
                    </GridContainer>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* MODAL MODULO EN DESARROLLO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>Este modulo se encuentra en desarrollo </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setAlertModal(false)} color="success" block>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL EDITAR TRANSFORMADOR */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={trabajosModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setTrabajosModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setTrabajosModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Registro de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Comentario
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="comentario"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (e) => {
                    setTrabajo({
                      ...trabajo,
                      comentario: e.target.value,
                    });
                  },
                  type: "text",
                  value: trabajo.comentario,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Responsable
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="Responsable"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    setTrabajo({
                      ...trabajo,
                      contratistaNombre: e.target.value,
                    });
                  },
                  type: "text",
                  value: trabajo.contratistaNombre,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Empresa
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="empresa"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    setTrabajo({
                      ...trabajo,
                      contratistaEmpresa: e.target.value,
                    });
                  },
                  type: "text",
                  value: trabajo.contratistaEmpresa,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <ReactFilestack
                apikey={API_KEY}
                // onSuccess={(result) => console.log(result)}
                onSuccess={(result) => setUrlFile(result.filesUploaded)}
                onError={(error) =>
                  setUrlFileError(
                    "Se ha producido un error, porfavor intentarlo nuevamente"
                  )
                }
                actionOptions={options}
                customRender={({ onPick }) => (
                  <div>
                    <Button fullWidth color="info" onClick={onPick}>
                      Cargar archivo
                    </Button>
                    {urlFile[0] ? (
                      <Success>
                        <p> Archivo cargado </p>
                      </Success>
                    ) : null}
                    {urlFileError ? (
                      <Danger>
                        <p>{urlFileError}</p>
                      </Danger>
                    ) : null}
                  </div>
                )}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleTrabajoSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setTrabajosModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL EDITAR TRANSFORMADOR */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={panelModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPanelModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPanelModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Registro de trabajo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Código de panel
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                id="codPanel"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    setCodigoPanel(e.target.value);
                  },
                  type: "text",
                  value: codigoPanel,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleTrabajoSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setPanelModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleNotaSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL Eliminar nota */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarNotaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar esta nota? Esta acción no puede deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteNota(preDeleteId, transformador._id)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarNotaModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL ARCHIVO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={filesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFilesModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir nombre de archivo"
            id="nameDescription"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setNameDescription(event.target.value);
              },
              type: "text",
              value: nameDescription,
            }}
          />
          {errorDescription ? (
            <Danger>
              <p> {errorDescription} </p>
            </Danger>
          ) : null}
          <ReactFilestack
            apikey={API_KEY}
            onSuccess={(result) => setUrlArchivo(result.filesUploaded[0])}
            onError={(error) =>
              setUrlFileError(
                "Se ha producido un error, porfavor intentarlo nuevamente"
              )
            }
            actionOptions={options}
            customRender={({ onPick }) => (
              <div>
                <Button fullWidth color="info" onClick={onPick}>
                  Cargar archivo
                </Button>
                {urlArchivo ? (
                  <Success>
                    <p> Archivo cargado </p>
                  </Success>
                ) : null}
                {urlArchivoError ? (
                  <Danger>
                    <p>{urlArchivoError}</p>
                  </Danger>
                ) : null}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleFileSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setFilesModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL Eliminar archivo */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarFileModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarFileModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarFileModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            ¿Está seguro de eliminar este archivo? Esta acción no puede
            deshacerse.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => deleteFile(preDeleteId, transformador._id)}
            color="success"
          >
            Eliminar
          </Button>
          <Button
            onClick={() => setEliminarFileModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
